.toast-top-right {
    top: 80px !important;
}

ul.error_msg_holder {
    padding: 10px;
}

li.error_msg {
    margin-left: 20px;
}

.id-errors {
    margin-top: 20px;
}

.id-errors li.error_msg {
    margin-left: 20px;
    text-align: center;
    list-style: none;
}
