// User Pages

a.auth-logo {
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.app-header__logo {
    background-color: #00999D !important;
}

.bg-portal {
    background-color: #00999D !important;
}

img.main-logo {
    height: 40px;
}

.icon-offset {
    margin-top: -18px !important;
}

.app-page-title {
    background: rgba(66, 153, 157, 0.24) !important;
}

.header-menu .nav-link {
    color: #fff !important;
}

.transaction-filter h4 {
    font-size: 18px;

    span {
        font-weight: bold;
        color: #00999D !important;
    }
}

.transaction-filter a.reset-filter {
    font-size: 12px;
    color: #666;
}

img.tbl-img {
    height: 100px;
}

.status-size {
    font-size: 25px;
}

.hidden {
    display: none;
}

.code-control {
    font-size: 12px;
}
